module.exports = [{
      plugin: require('../../../node_modules/@solublestudio/soluto-design-system/gatsby-browser.js'),
      options: {"plugins":[],"variablesPath":"/working_dir/packages/design-system/src/scss/variables.scss","components":["Accordion","Button","Card","Col","Collapse","Container","GridBox","GridContainer","GridWrapper","Header","Heading","HubspotForm","Icon","Image","Layout","Link","Modal","Row","Section","SocialIcons","Text","Video","VideoInline","HTMLComponent","Slider"],"alias":{"~icons":"/working_dir/packages/design-system/icons.js","@ds/components":"/working_dir/packages/design-system/src/components","@ds/hooks":"/working_dir/packages/design-system/src/hooks","@ds/utils":"/working_dir/packages/design-system/src/utils","@ds/scss":"/working_dir/packages/design-system/src/scss","@ds/types":"/working_dir/packages/design-system/src/types","@ds/img":"/working_dir/packages/design-system/src/img","@ds/stories":"/working_dir/packages/design-system/src/stories"},"dirname":"/working_dir/packages/design-system","sal":true},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1280,"quality":90,"withWebp":true,"srcSetBreakpoints":[576],"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1280,"quality":90,"withWebp":true,"srcSetBreakpoints":[576],"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../node_modules/@solublestudio/gatsby-theme-soluble-source/gatsby-browser.js'),
      options: {"plugins":[],"primaryLanguage":"en","language":{"en":""},"pathsByLanguage":{},"datocms":{"previewMode":true,"apiToken":"892cac57df7e6c49d24ce50437113e","modelPages":["Page"]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/img/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ea551c624129c5e790e6f1ec7bbfc0b4"},
    }]
